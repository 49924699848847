<template>
    <div class="p-5">
        <div class="bg-white rounded-sm border">
            <fom-customer :type="'new'" v-model="customer" @setDisabled="onDisabled($event)" ref="formCustomer"></fom-customer>
        </div>
    </div>
</template>

<script>
import fomCustomer from '../../components/formCustomer.vue'
import BreadcrumbManager from '../../mixins/breadcrumbsManager.vue'
import {EventBus} from '../../event-bus'
export default {
    components: {fomCustomer},
    mixins: [BreadcrumbManager],
    data () {
        return {
            customer: ''
        }
    },
    mounted () {
        this.setBreadcrumbs([
            {text: 'Customers', path: '/customers'},
            {text: 'New'}
        ])
        this.setButtonText('SAVE')
        this.setDiscard(true)
        this.setDiscardText('DISCARD')
        EventBus.$on('onCancel', this.onCancel)
        EventBus.$on('onClick', this.onSubmit)
    },
    destroyed () {
        this.setBreadcrumbs([
            {text: 'Customers', path: '/customers'},
        ])
        EventBus.$off('onCancel', this.onCancel)
        EventBus.$off('onClick', this.onSubmit)
        this.setDisabled(false)
    },
    methods: {
        onDisabled(e) {
            this.setDisabled(e)
        },
        onSubmit () {
            this.$refs.formCustomer.onSubmit()
        },
        onCancel () {
            const path = '/customers'
            if(this.$route.path != path) this.$router.push('/customers')
        }
    },
    watch: {
        customer (val) {
            if(val) {
                this.$router.push('/customers/detail/'+val._id)
            }
        }
    }
}
</script>